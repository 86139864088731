import { string } from 'yup';
import { ICrossCharge } from '../types/common.interface';
import { INewService } from '../types/service.interfaces';
import { baseApi } from './baseApi';

const chargingReportApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		deleteAzureReport: builder.mutation<void, { id: number }>({
			query: (id) => ({
				url: `azure/delete/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['AzureReport'],
		}),
		deleteChargingReport: builder.mutation<void, { id: number }>({
			query: (id) => ({
				url: `chargingReport/delete/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['ChargingReport'],
		}),
		uploadChargingReport: builder.mutation<
			void,
			{ file: FormData | null; month: string; vId: number; uploadType: String; }
		>({
			query: ({ file, month, vId, uploadType }) => ({
				url: `chargingReport/uploaddata?uploadedMonthYear=${month}&chargingReportId=${vId}&uploadType=${uploadType}`,
				method: 'POST',
				body: file,
			}),
			invalidatesTags: ['Notifications', 'MappingUpdates', 'ChargingReport'],
		}),
		sendCRUploadNotification: builder.mutation<any, {uploadType: string}>({
			query: ({uploadType}) => ({
				url: `chargingReport/notification?uploadType=${uploadType}`,
				method: 'POST',
			}),
		}),
		// uploadAzureReport: builder.mutation<
		// 	any,
		// 	{ file: FormData; monthYear: string; vId: number }
		// >({
		// 	query: ({ file, monthYear, vId }) => ({
		// 		url: `azure/uploadfile?uploadedMonthYear=${monthYear}&exceluploadId=${vId}`,
		// 		method: 'POST',
		// 		body: file,
		// 		responseHandler: (response) => response.text(),
		// 	}),
		// 	invalidatesTags: ['AzureReport'],
		// }),
		uploadAzureReport: builder.mutation<
			any,
			{ monthYear: string; }
		>({
			query: ({ monthYear }) => ({
				url: `azure/sampleapi?month=${monthYear}`,
				method: 'POST',
				responseHandler: (response) => response.text(),
			}),
			invalidatesTags: ['AzureReport'],
		}),
		uploadCostcenters: builder.mutation<void, { file: FormData }>({
			query: ({ file }) => ({
				url: `costcenter/uploadcostcenter`,
				method: 'POST',
				body: file,
			}),
		}),
		uploadCCMasterList: builder.mutation<void, { file: FormData }>({
			query: ({ file }) => ({
				url: `costcenter/uploadccmaster`,
				method: 'POST',
				body: file,
			}),
		}),
		uploadGemsUserList: builder.mutation<string, { file: FormData }>({
			query: ({ file }) => ({
				url: `chargingReport/uploaduserlist`,
				method: 'POST',
				body: file,
			}),
		}),
		chargingReportOverview: builder.mutation<any, number>({
			query: (year) => ({
				url: `chargingReport/overview?year=${year}`,
				method: 'POST',
			}),
		}),
		getWildCardMapping: builder.query<any, void>({
			query: () => ({
				url: `/serviceDetails/wildcardservices`,
				method: 'GET',
			}),
		}),
		getNewServices: builder.query<INewService[], void>({
			query: () => ({ url: `/serviceDetails/newservices`, method: 'GET' }),
		}),
		getUploadTypes: builder.query<any, void>({
			query: () => ({
				url: `/chargingReport/getuploadtypes`,
				method: 'GET',
			}),
		}),
		createCrossCharge: builder.mutation<any, ICrossCharge>({
			query: (data) => ({
				url: `chargingReport/uploadformdata`,
				method: 'POST',
				body: data
			}),
			invalidatesTags: ['CrossCharge'],
		}),
		updateCrossCharge: builder.mutation<any, ICrossCharge>({
			query: (data) => ({
				url: `chargingReport/updateformdata`,
				method: 'PUT',
				body: data
			}),
			invalidatesTags: ['CrossCharge'],
		}),
		deleteCrossCharge: builder.mutation<void, { id: number }>({
			query: (id) => ({
				url: `chargingReport/deleteformdata/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['CrossCharge'],
		}),
		getCRFormData: builder.query<any, void>({
			query: () => ({ url: `/chargingReport/getCRformdata`, method: 'GET' }),
			providesTags: ['CrossCharge'],
		}),
		setNewServices: builder.mutation<
			void,
			{
				costCenterId: string;
				keyWord: string;
				serviceId: string;
				isMapped: boolean;
			}
		>({
			query: ({ costCenterId, keyWord, serviceId, isMapped }) => ({
				url: `/serviceDetails/mapnewservice`,
				method: 'PUT',
				body: {
					costCenterId,
					keyWord,
					serviceId,
					isMapped,
				},
			}),
		}),
		clearCache: builder.mutation<any, any>({
			query: (uploadType) => ({
				url: `upload/clearCache?type=${uploadType}`,
				method: 'PUT'
			}),
			invalidatesTags: ['ChargingReport', 'PicasNewReports', 'AzureReport'],
		}),
		getAllCCMaster: builder.query<any, void>({
			query: () => ({ url: `/costcenter/getallccmaster`, method: 'GET' }),
		}),
	}),
});

export const {
	useDeleteChargingReportMutation,
	useGetNewServicesQuery,
	useGetWildCardMappingQuery,
	useSetNewServicesMutation,
	useUploadChargingReportMutation,
	useUploadCostcentersMutation,
	useUploadAzureReportMutation,
	useDeleteAzureReportMutation,
	useChargingReportOverviewMutation,
	useUploadGemsUserListMutation,
	useSendCRUploadNotificationMutation,
	useGetUploadTypesQuery,
	useGetCRFormDataQuery,
	useCreateCrossChargeMutation,
	useUpdateCrossChargeMutation,
	useDeleteCrossChargeMutation,
	useClearCacheMutation,
	useUploadCCMasterListMutation,
	useGetAllCCMasterQuery,
} = chargingReportApi;
