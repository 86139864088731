import { result } from 'lodash';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DisplayIcon from '../../components/icon';
import useFindCurrentLocation from '../../hooks/useFindCurrentLocation';
import { useGetAllActiveCostCentersQuery } from '../../store/apiSlice';
import {
	getAssetSummaryFilter,
	getSearchTerm,
	setAssetListModal,
	setDataProcessingModal,
} from '../../store/commonSlice';
import {
	useGetChargingOverviewQuery,
	useLazyGetAssetListByCCQuery,
} from '../../store/picasApi';
import { AssetListHeader } from '../asset-list-upload';

const AssetSummaryList = () => {
	/* 	const [getChargingOverView, { isLoading }] = useGetChargingOverviewMutation();
	useEffect(() => {
		const makeRequest = async () => {
			let result = await getChargingOverView().unwrap();
			setData(result);
		};
		makeRequest();
	}, []); */

	interface customAssetList {
		cc_Id: number;
		costCenters: number;
		highlight: boolean;
		total: number;
	}

	const {
		data: assetData,
		isLoading,
		isSuccess,
		isError,
		error,
	} = useGetChargingOverviewQuery();

	const dispatch = useDispatch();
	const { data = [] } = useGetAllActiveCostCentersQuery();
	const [getAssetDataByCC] = useLazyGetAssetListByCCQuery();

	const [allMonths, setAllMonths] = useState<any>();
	const searchTerm = useSelector(getSearchTerm);
	const filters = useSelector(getAssetSummaryFilter);
	const [modalData, setModalData] = useState<any>();
	const [id, setId] = useState(-1);
	const computedData = useMemo(() => {
		if (assetData && data) {
			const months = [...assetData.months].reverse();

			const uniqueCCIds = Array.from(
				new Set(assetData.ccList.map((item: any) => item.cc_Id))
			);
			let result = [];
			for (const CCId of uniqueCCIds) {
				const item: any = {};
				item['cc_Id'] = CCId;
				item['costCenters'] = assetData.ccList.find(
					(item) => item.cc_Id === CCId
				)?.costCenters;
				item['highlight'] =
					data.findIndex((ele) => ele.costCenter === item['costCenters']) !==
					-1;
				item['total'] = 0;
				console.log('Months :: ', months);
				for (const month of months) {
					const foundItem = assetData.ccList.find(
						(item: any) => item.cc_Id === CCId && item.monthYear === month
					);
					item['total'] += foundItem !== undefined ? foundItem.count : 0;
					item[month] = foundItem !== undefined ? foundItem.count : 0;
				}
				result.push(item);
			}
			if (searchTerm && searchTerm.length > 0) {
				result = result.filter(
					(row) =>
						Object.values(row).findIndex((chunk: any) =>
							chunk.toString().toLowerCase().includes(searchTerm.toLowerCase())
						) !== -1
				);
			}
			if (filters.active === 'active') {
				result = result.filter((row) => row.highlight == true);
			}
			if (filters.active === 'inactive') {
				result = result.filter((row) => row.highlight == false);
			}
			return result;
		} else {
			return [];
		}
	}, [assetData, searchTerm, filters.active]);
	const assetDataByCC = async (id: number) => {
		if (id) {
			console.log('ID ::', id);
			const { data: dataByCC } = await getAssetDataByCC(id);

			if (dataByCC) {
				const uniqueDevices: string[] = [
					...Array.from(
						new Set<string>(dataByCC.ccList.map((item: any) => item.asset))
					),
				];
				const allDeviceList = [];
				for (let device of uniqueDevices) {
					let item: any = {};
					item['device'] = device;
					item['total'] = 0;
					let total = 0;
					for (let month of allMonths) {
						const foundItem = dataByCC.ccList.find(
							(i: any) => i.asset === device && i.monthYear === month
						);
						const assetCount = foundItem !== undefined ? foundItem.count : 0;
						item[month] = assetCount;

						total += assetCount;
						if (foundItem != undefined) {
							item['costCenter'] = foundItem.costCenter;
						}
					}
					item['total'] = total;
					allDeviceList.push(item);
				}
				return allDeviceList;
			}
			return [];
		}
		return [];
	};
	useEffect(() => {
		document.title = 'Asset Summary | XCharge';
	}, []);

	useEffect(() => {
		if (assetData) {
			if (filters && filters.year) {
				if (filters.year !== 9999) {
					setAllMonths([
						...[...assetData.months]
							.reverse()
							.filter((month: string) =>
								month.includes(filters.year.toString())
							),
					]);
				} else {
					setAllMonths([...assetData.months].reverse());
				}
			}
		}
	}, [computedData, filters.year]);
	const rowClass = (data: any) => {
		return {
			'highlight-row': data.highlight == true,
		};
	};
	const currentPage = useFindCurrentLocation();
	const summaryHeader = () => {
		return (
			<div className="flex align-items-center justify-content-between">
				<div className="flex align-items-center">
					<h5>
						Asset count per month for Cost Center :{' '}
						{modalData
							? modalData.length > 0
								? modalData[0].costCenter
								: '-'
							: '-'}
					</h5>
				</div>
			</div>
		);
	};
	return (
		<>
			{Array.isArray(allMonths) && (
				<div className={`grid`}>
					<div className="cards">
						<DataTable
							value={computedData}
							responsiveLayout="scroll"
							header={
								<AssetListHeader
									title="Asset Summary"
									currentPage={currentPage}
								/>
							}
							rowClassName={rowClass}
							onRowClick={async (e) => {
								console.log(e);
								setId(e.data.cc_Id);
								setModalData(await assetDataByCC(e.data.cc_Id));
							}}
						>
							<Column
								header="#"
								alignHeader="left"
								body={(data, props) => props.rowIndex + 1}
							></Column>
							<Column field="costCenters" header="Cost Center" />
							{allMonths.map((col, i) => {
								return <Column key={col} field={col} header={col} />;
							})}
						</DataTable>
					</div>
				</div>
			)}
			{id !== -1 ? (
				<Dialog
					header="Cost Center Asset Summary"
					visible={id !== -1}
					style={{ width: 'auto' }}
					onHide={() => {
						setId(-1);
					}}
				>
					<div className={`grid`}>
						<div className="cards">
							<DataTable
								header={summaryHeader}
								value={modalData}
								responsiveLayout="scroll"
								onRowClick={(e) => {}}
							>
								<Column
									header="#"
									alignHeader="left"
									body={(data, props) => props.rowIndex + 1}
								></Column>
								<Column field="device" header="Device" />
								{allMonths.map((col: any, i: any) => {
									return <Column key={col} field={col} header={col} />;
								})}

								<Column field="total" header="Total" />
							</DataTable>
						</div>
					</div>
				</Dialog>
			) : null}
		</>
	);
};

export default AssetSummaryList;
