import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Row } from 'primereact/row';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DisplayIcon from '../../components/icon';
import {
	commonState,
	getIsAdmin,
	getServiceOptions,
	setMailers,
	setServiceOptions,
} from '../../store/commonSlice';
import {
	IHistoryResponse,
	IService,
	IServiceDetails,
	IServiceMonthlyData,
} from '../../types/service.interfaces';
import PopOver from './popover';
import CommentsDialog from './comments-dialog';
import useDownloadFile from '../../hooks/useDownloadFile';
import MailServiceResponsible from '../../components/email/email-service-responsible';
import { useGetCurrentUserQuery } from '../../store/UserApi';
import {
	checkAzureService,
	formatCurrency,
	formatDateDDMMYYYY,
	sortByMonth,
	sortingFunction,
} from '../../utils/utility-function';
import { useGetAllHistoryMutation } from '../../store/servicesApi';
import useUpdateIO from '../../hooks/useUpdateIO';
import { InputText } from 'primereact/inputtext';
import ReactTooltip from 'react-tooltip';
import { useParams } from 'react-router-dom';
import { Button } from 'primereact/button';

const ServiceDetailsDialog = ({
	monthlyData,
	monthlyClusteredData,
	selectedService,
	costCenterId,
	isArchive,
	showSwap,
}: {
	monthlyData: IServiceDetails | undefined;
	monthlyClusteredData: Partial<IServiceMonthlyData>[];
	selectedService: IService & { clusterInfo?: any };
	costCenterId: number;
	isArchive: boolean;
	showSwap?: boolean;
}) => {
	const [history, setHistory] = useState<IHistoryResponse[]>();
	const [editIO, setEditIO] = useState(false);
	const [IO, setIO] = useState(selectedService.ioNumber);
	const { updateIONumber } = useUpdateIO();
	const dispatch = useDispatch();
	const isAdmin = useSelector(getIsAdmin);
	const showDialog = useSelector(getServiceOptions).serviceDetails;
	const { data: currentUser, isLoading: isLoadingUser } =
		useGetCurrentUserQuery(0);
	const params = useParams();
	const [isPopoverOpen, setIsPopoverOpen] = useState(-1);
	const isClusterEnabled = useSelector(commonState).clusterServices;
	const { downloadFile } = useDownloadFile();
	const [getHistory, { isLoading: isGettingHistory }] =
		useGetAllHistoryMutation();

	const header = () => {
		return (
			<div className="flex align-items-center justify-content-between">
				<h5>Cost Center History</h5>
			</div>
		);
	};

	const openInNewTab = (url: string) => {
		window.open(url, '_blank', 'noreferrer');
	};

	const historyTable = () => {
		return (
			<div className="cards history-table">
				<DataTable
					value={history}
					header={header}
					rowHover
					onClick={(e) => e.stopPropagation()}
				>
					<Column
						header="#"
						alignHeader="left"
						body={(data, props) => props.rowIndex + 1}
					></Column>
					<Column
						field="updatedOn"
						header="Updated On"
						body={(data: IHistoryResponse) =>
							formatDateDDMMYYYY(data.updatedOn)
						}
					></Column>
					<Column field="updatedBy.firstName" header="Updated By"></Column>
					<Column field="oldCostcenter" header="Old Cost Center"></Column>
					<Column field="updatedCc" header="New Cost Center"></Column>
					<Column field="names" header="Months"></Column>
				</DataTable>
			</div>
		);
	};
	let footerGroup = (
		<ColumnGroup>
			<Row>
				<Column footer="Totals:" colSpan={1} />
				<Column
					footer={
						monthlyData &&
						formatCurrency(
							monthlyData.data.reduce((acc, d) => (acc += d.bookedAmount), 0)
						)
					}
				/>
				<Column
					footer={
						monthlyData &&
						formatCurrency(
							monthlyData.data.reduce((acc, d) => (acc += d.plannedAmount), 0) -
							monthlyData.data.reduce((acc, d) => (acc += d.bookedAmount), 0)
						)
					}
				/>
				<Column
					footer={
						monthlyData &&
						formatCurrency(
							monthlyData.data.reduce((acc, d) => (acc += d.plannedAmount), 0)
						)
					}
				/>
			</Row>
		</ColumnGroup>
	);

	const computeDownloadPayload = () => {
		let _services = [];
		if ('clusterInfo' in selectedService) {
			for (let _clusterInfo of selectedService.clusterInfo) {
				_services.push({
					...selectedService,
					additionalInfo: _clusterInfo,
				});
			}
		} else {
			_services.push({ ...selectedService });
		}

		return _services.map((s) => {
			let azureService = undefined;
			if (
				s.serviceName.toLocaleLowerCase().includes('gb4') &&
				s.serviceId === '20023510' &&
				Object.keys(s).indexOf('additionalInfo') === -1
			) {
				azureService = 'null';
			}

			return {
				comment: 'null',
				serviceId: s.serviceId,
				serviceName: s.serviceName,
				said: s.said,
				additionalInformation: azureService || s.additionalInfo,
				cc_Id: costCenterId,
				versionId: params.versionId ? Number(params.versionId) : undefined,
			};
		});
	};

	ReactTooltip.rebuild();

	return (
		<>
			<Dialog
				header="Service Details"
				visible={showDialog}
				breakpoints={{ '960px': '100vw', '640px': '100vw' }}
				style={{ width: '80vw' }}
				onHide={() => dispatch(setServiceOptions({ serviceDetails: false }))}
				className="service-details"
			>
				<div className="flex service-info align-items-center flex-column mb-4 transition-all transition-linear transition-duration-300 mt-2">
					<div className="flex  align-items-center justify-content-between mb-4 w-full">
						<h4>{monthlyData?.serviceName}</h4>
						<h6 style={{ marginLeft: '1rem' }}>{monthlyData?.serviceId}</h6>
					</div>

					<div className="flex justify-content-between w-full">
						<div className="flex align-items-center justify-content-between">
							<DisplayIcon icon="map" className="card-icon" />
							<div className="flex flex-column ml-2">
								<span>SAID</span>
								<h3>{monthlyData?.said}</h3>
							</div>
						</div>
						<div
							className="flex align-items-center justify-content-between"
							style={{
								borderLeft: '1px solid #e7e9f0',
								paddingLeft: '2rem',
								marginLeft: '1rem',
							}}
						>
							<DisplayIcon icon="calendar" className="card-icon" />

							<div className="flex flex-column ml-2">
								<span>Year</span>
								<h3>{monthlyData?.year}</h3>
							</div>
						</div>
						<div
							className="flex align-items-center justify-content-between"
							style={{
								borderLeft: '1px solid #e7e9f0',
								paddingLeft: '2rem',
								marginLeft: '1rem',
							}}
						>
							<DisplayIcon icon="contactCard" className="card-icon" />

							<div className=" flex flex-column ml-2">
								<span>Service Responsible</span>
								<div className="flex align-items-center">
									<h3
										className="can-mail-user"
										onClick={() =>
											dispatch(setMailers({ serviceResponsible: true }))
										}
									>
										{monthlyData?.responsible}
									</h3>
									<i className="bx bx-envelope b-icon-rounded ml-2 mb-1"></i>{' '}
								</div>
							</div>
						</div>
						<div
							className="flex align-items-center justify-content-between"
							style={{
								borderLeft: '1px solid #e7e9f0',
								paddingLeft: '2rem',
								marginLeft: '1rem',
							}}
						>
							{!editIO ? (
								<DisplayIcon icon="ticket" className="card-icon" />
							) : null}

							<div
								className="flex flex-column ml-1"
								onClick={(e) => e.stopPropagation()}
							>
								{!editIO ? (
									<>
										<span>IO #</span>
										<h3 className='io-number'>
											{selectedService.ioNumber &&
												selectedService.ioNumber.length > 0
												? selectedService.ioNumber
												: '-'}
										</h3>
									</>
								) : (
									<span className="p-float-label">
										<InputText
											name="ionumber"
											value={IO}
											onChange={(e) => setIO(e.target.value)}
										/>
										<label htmlFor="ionumber">IO #</label>
									</span>
								)}
							</div>
						</div>
						<div
							className="flex justify-content-between align-items-center w-min-content transition-all transition-linear transition-duration-300"
							style={{ marginLeft: '2rem' }}
						>
							{!editIO ? (
								!isArchive ? (
									<Button
										className="p-button-rounded p-button-text p-button-icon-only"
										tooltip="Edit IO Number"
										tooltipOptions={{ position: 'top' }}
										onClick={(e: any) => {
											e.stopPropagation();
											setEditIO(true);
										}}
									>
										<DisplayIcon className="rounded-button mr-1 " icon="edit" />
									</Button>
								) : null
							) : (
								<>
									<Button
										className="p-button-rounded p-button-text p-button-icon-only"
										tooltip="Reset To Default"
										tooltipOptions={{ position: 'top' }}
										onClick={(e: any) => {
											e.stopPropagation();
											setEditIO(false);
										}}
									>
										<DisplayIcon className="rounded-button mr-1" icon="reset" />
									</Button>
									{IO !== selectedService.ioNumber ? (
										<Button
											className="p-button-rounded p-button-text p-button-icon-only"
											tooltip="Save IO Number"
											tooltipOptions={{ position: 'top' }}
											onClick={(e: any) => {
												e.stopPropagation();
												updateIONumber({
													cc_Id: costCenterId,
													serviceList: selectedService,
													ioNumber: IO,
												});
												setIO('');
												setEditIO(false);
												dispatch(setServiceOptions({ serviceDetails: false }));
											}}
										>
											<DisplayIcon
												className="rounded-button"
												icon="tablerSave"
											/>
										</Button>
									) : (
										<Button
											className="p-button-rounded p-button-text p-button-icon-only"
											tooltip="Cancel IO Number Update"
											tooltipOptions={{ position: 'top' }}
											onClick={(e: any) => {
												e.stopPropagation();
												setEditIO(false);
											}}
										>
											<DisplayIcon
												className="rounded-button mr-1"
												icon="dismiss"
											/>
										</Button>
									)}{' '}
								</>
							)}
							{selectedService.isHistory && (
								<Button
									className="p-button-rounded p-button-text p-button-icon-only"
									tooltip="Display Cost Re-Assignment History"
									tooltipOptions={{ position: 'top' }}
									onClick={async (e: any) => {
										e.stopPropagation();

										let history = await getHistory({
											cc_Id: costCenterId,
											comment: 'null',
											said: selectedService.said,
											serviceId: selectedService.serviceId,
											serviceName: selectedService.serviceName,
											additionalInformation: checkAzureService(selectedService),
										}).unwrap();
										setHistory(history);
										setIsPopoverOpen(1);
									}}
								>
									<DisplayIcon icon="history" className="rounded-button mr-1" />
								</Button>
							)}
							{isPopoverOpen !== -1 && (
								<PopOver
									callback={() => {
										setIsPopoverOpen(-1);
									}}
								>
									{historyTable}
								</PopOver>
							)}
							{!editIO && (
								<>
									{!isArchive && showSwap ? (
										<Button
											className="p-button-rounded p-button-text p-button-icon-only"
											tooltip="Re-Assign Service To Different Cost Center"
											tooltipOptions={{ position: 'top' }}
											onClick={() =>
												dispatch(setServiceOptions({ reAssignDialog: true }))
											}
										>
											<DisplayIcon
												icon="swap"
												className="rounded-button mr-1"
											/>
										</Button>
									) : null}
									{checkAzureService(selectedService) !== 'null' && (
										<Button
											className="p-button-rounded p-button-text p-button-icon-only"
											tooltip="Export Service Details as Excel File"
											tooltipOptions={{ position: 'top' }}
											onClick={() => {
												const payload = computeDownloadPayload();
												if (isArchive) {
													downloadFile(
														'archive/exportservice',
														'Service-Details-Archive_' +
														new Date().toISOString() +
														'.xlsx',
														payload
													);
												} else {
													downloadFile(
														'actuals/exportservice',
														'Service-Details_' +
														new Date().toISOString() +
														'.xlsx',
														payload
													);
												}
											}}
										>
											<DisplayIcon icon="excel" className="excel-icon__small" />
										</Button>
									)}
								</>
							)}{' '}
						</div>
					</div>

					<div className="flex justify-content-between w-full mt-4">
						<div className="flex align-items-center justify-content-between">
							<DisplayIcon icon="info" className="info-icon" />
							<div className="flex">
								<h4 className="info">
									{monthlyData?.additionalInfo &&
										monthlyData?.additionalInfo.length > 0
										? monthlyData?.additionalInfo
										: '-'}
								</h4>
							</div>
						</div>
						{
							monthlyData?.serviceName.includes("ServiceNow") &&
							<div className="flex align-items-center justify-content-between">
								{/* <DisplayIcon icon="info" className="info-icon" /> */}
								<div className="flex">
									<h4 className="info">
										KB Article: 
									</h4>
									<h3
										className="can-mail-user"
										onClick={() =>
											openInNewTab('https://servicenow.i.mercedes-benz.com/esc?id=kb_article&table=kb_knowledge&sys_kb_id=8ac9e871dbede91076c6000cd39619f9&spa=1')
										}
									>
										Knowledge Base - What does ServiceNow cost?
									</h3>
								</div>
							</div>
						}
					</div>
				</div>

				<div className="table-responsive mb-8">
					<div className="cards">
						<DataTable
							value={monthlyData && sortByMonth(monthlyData.data)}
							responsiveLayout="scroll"
							footerColumnGroup={footerGroup}
						>
							<Column field="month" header="Month"></Column>
							<Column
								header="Booked"
								body={(data) => formatCurrency(data.bookedAmount)}
							></Column>
							<Column
								header="Deviation"
								body={(data) =>
									formatCurrency(data.plannedAmount - data.bookedAmount)
								}
							></Column>
							<Column
								header="Planned"
								body={(data) => {
									ReactTooltip.rebuild();

									return formatCurrency(data.plannedAmount);
								}}
							></Column>
							<Column
								header="Forecast"
								body={(data) => {
									return formatCurrency(data.yeralyTotal);
								}}
							></Column>
						</DataTable>
					</div>
				</div>
			</Dialog>
			{monthlyData && currentUser && (
				<MailServiceResponsible
					defaultTo={[monthlyData.responsible]}
					defaultCC={[currentUser.email]}
					defaultSubject={`XCharge - ${monthlyData.said} - ${monthlyData.serviceId} - ${monthlyData.serviceName} `}
				/>
			)}
		</>
	);
};

export default ServiceDetailsDialog;
