import { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import {
	dataProcessingModalState,
	setDataProcessingModal,
} from '../../store/commonSlice';
import FileUploader from '../../components/file-uploader';
import { Dropdown } from 'primereact/dropdown';
import CustomAutoComplete from '../../components/autocomplete/autocomplete';
import { Months } from '../../utils/json/months';
import { useClearCacheMutation, useSendCRUploadNotificationMutation, useUploadAzureReportMutation, useUploadChargingReportMutation } from '../../store/chargingReportApi';
import { useGetAzureChargingReportQuery } from '../../store/apiSlice';
import { Skeleton } from 'primereact/skeleton';
import { toast } from 'react-toastify';
import { useGetICAGeneratedMonthsQuery } from '../../store/icaApi';

const UploadAzureReport = () => {
	const { data } = useGetAzureChargingReportQuery();

	const dispatch = useDispatch();
	const openModal = useSelector(dataProcessingModalState);
	const [selectedFile, setSelectedFile] = useState<File | undefined>();
	const [selectedMonth, setSelectedMonth] = useState('');
	const [selectedYear, setSelectedYear] = useState('');
	const [sendCRUploadNotification, { }] = useSendCRUploadNotificationMutation();

	const [clearCache] = useClearCacheMutation();
	const [uploadChargingReport, { isLoading: isUploading }] = useUploadChargingReportMutation();

	const { data: ICAMonthsData = [], isLoading: isMonthsFetching, } = useGetICAGeneratedMonthsQuery();
	const filteredMonths = Months.filter(o1 => !ICAMonthsData.some((o2: any) => o1.month === o2.uploadedMonth))

	const footer = () => (
		<div>
			<Button
				label="Cancel"
				icon="pi pi-times"
				onClick={() =>
					dispatch(setDataProcessingModal({ uploadAzureChargingReport: false }))
				}
			/>
			<Button
				label="Upload"
				icon="pi pi-save"
				loading={isUploading}
				disabled={
					// selectedFile === undefined ||
					selectedMonth.length === 0
				}
				onClick={async () => {
					console.log(selectedFile);
					const uploadedMonthYear = `${selectedMonth}-${new Date().getFullYear()}`;
					const reportAlreadyExist = data
						? data.filter(
								(report: any) => report.uploadedMonthYear === uploadedMonthYear
						  )
						: [];

					const exceluploadId =
						reportAlreadyExist.length > 0 ? reportAlreadyExist[0].id : 0;

					if (selectedMonth.length !== 0) {
						// if (selectedFile) {
							// const payload = new FormData();
							// payload.append('importdata', selectedFile);
							// await uploadAzureReport({
							// 	file: payload,
							// 	monthYear: `${selectedMonth}-${new Date().getFullYear()}`,
							// 	vId: exceluploadId,
							// })
							// const month = Months.filter((item) => item.month === selectedMonth)
							// await uploadAzureReport({
							// 	monthYear: `${new Date().getFullYear()}-${month[0].id.toString().padStart(2, '0')}`
							// })
							// .unwrap()
							// .then((payload) => {
							// 	toast.success('Cloud Costs Charging Report Uploaded Successfully!');
							// })
							// .catch((e: any) => {
							// 	toast.error('Error Uploading Cloud Costs Report');
							// });
					const payload = new FormData();
					// if (selectedUploadType?.name?.toLowerCase() === 'itservice' && selectedFile) payload.append('importdata', selectedFile)
					payload.append('importdata', '');
					await uploadChargingReport({
						file: payload,
						month: uploadedMonthYear,
						uploadType: 'CloudCosts',
						vId: 0,
					})
						.unwrap()
						.then((payload) => {
							toast.success('Cloud Costs Report Uploaded Successfully!');
							// process.env.REACT_APP_ENV === 'production' && 
							// sendCRUploadNotification({}).unwrap().then().catch();
							window.open(
								`${process.env.REACT_APP_ENV === 'staging'
									? '/web/XCharge'
									: '#'
								}/dataProcessing/reportUpload/newServices`,
								'_blank'
							);
							clearCache('itservice').then(() => console.log('cache cleared successfully'));
							setSelectedFile(undefined);
							setSelectedMonth('');
						})
						.catch((e: any) =>
							toast.error('Error Cloud Costs Charging Report')
						);
						dispatch(
							setDataProcessingModal({ uploadAzureChargingReport: false })
						);
					}
				}}
			/>
		</div>
	);

	const fileCallback = (file: File | undefined) => {
		setSelectedFile(file);
	};

	return (
		<Dialog
			header="Upload Cloud Costs Charging Report"
			visible={openModal.uploadAzureChargingReport}
			style={{ width: '30vw' }}
			footer={footer}
			onHide={() =>
				dispatch(setDataProcessingModal({ uploadAzureChargingReport: false }))
			}
		>
			<div className="grid p-fluid">
				<div className="grid col-12">
					{/* <FileUploader callback={fileCallback} disabled={isUploading} /> */}
					{isUploading ? (
						<Skeleton height="2rem" className="w-full mt-3" />
					) : (
						<div className="flex field col-6 col-offset-2 mt-3">
							{/* <CustomAutoComplete
								shellClass="mr-4"
								customId="select-month"
								placeholder="Select Month"
								inputList={Months}
								field="month"
								target="month"
								callback={(item) => {
									if (item) {
										setSelectedMonth(item.month);
									} else {
										setSelectedMonth('');
									}
								}}
							/> */}
							<CustomAutoComplete
								shellClass="mr-4"
								customId="select-month"
								placeholder="Select Month"
								inputList={filteredMonths}
								field="month"
								target="month"
								callback={(item) => {
									if (item) {
										setSelectedMonth(item.month);
									} else {
										setSelectedMonth('');
									}
								}}
							/>
							{/* <CustomAutoComplete
								customId="select-year"
								placeholder="Select Year"
								inputList={[
									{ value: '2022', key: '2022' },
									{ value: '2021', key: '2021' },
									{ value: '2020', key: '2020' },
								]}
								field="key"
								target="value"
								callback={(item) => setSelectedYear(item.value)}
							/> */}
						</div>
					)}
				</div>
			</div>
		</Dialog>
	);
};

export default UploadAzureReport;
