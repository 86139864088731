import { useLocation, useParams } from 'react-router-dom';
import PAGES from '../utils/pages.enum';

const useFindCurrentLocation = () => {
	const location = useLocation();
	const params = useParams();

	let pageName: PAGES = PAGES.UNDEFINED;
	if (!('costCenterId' in params) && location.pathname.includes('itServices')) {
		pageName = PAGES.ITSERVICES;
	} else if (
		'costCenterId' in params &&
		location.pathname.includes('itServices')
	) {
		pageName = PAGES.SERVICES;
	} else if (location.pathname === '/actuals/itServices') {
		pageName = PAGES.ITSERVICES;
	} else if (location.pathname === '/actuals/workplace') {
		pageName = PAGES.PICAS;
	} else if (location.pathname === '/invalidUser') {
		pageName = PAGES.INVALID;
	} else if (location.pathname === '/dataProcessing/reportUpload') {
		pageName = PAGES.CHARGING_REPORT;
	} else if (location.pathname === '/dataProcessing/reportUpload/wildCard') {
		pageName = PAGES.WILDCARD_MAPPING;
	} else if (location.pathname === '/dataProcessing/reportUpload/newServices') {
		pageName = PAGES.NEW_SERVICES;
	} else if (location.pathname === '/dataProcessing/reportUploadMBAG') {
		pageName = PAGES.CHARGING_REPORT_MBAG;
	} else if (location.pathname === '/dataProcessing/reportUploadMBAG/wildCard') {
		pageName = PAGES.WILDCARD_MAPPING_MBAG;
	} else if (location.pathname === '/dataProcessing/reportUploadMBAG/newServices') {
		pageName = PAGES.NEW_SERVICES_MBAG;
	} else if (location.pathname === '/dataProcessing/assetListUpload') {
		pageName = PAGES.PICAS_LIST;
	} else if (location.pathname === '/dataProcessing/assetNewListUpload') {
		pageName = PAGES.WORKPLACE_LIST;
	} else if (
		location.pathname === '/dataProcessing/assetListUpload/assetSummary'
	) {
		pageName = PAGES.ASSET_SUMMARY;
	} else if (
		location.pathname.includes('archive') &&
		'versionId' in params &&
		'costCenterId' in params
	) {
		pageName = PAGES.ARCHIVE_SERVICES;
	} else if (location.pathname.includes('archive') && 'versionId' in params) {
		pageName = PAGES.ARCHIVE;
	} else {
		pageName = PAGES.UNDEFINED;
	}

	return pageName;
};

export default useFindCurrentLocation;
