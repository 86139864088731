import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { setRefetchServices } from '../store/commonSlice';
import {
	useUpdateIONumberAzureMutation,
	useUpdateIONumberMutation,
} from '../store/ioNumberApi';
import { IIONumberUpdateRequest } from '../types/ionumber.interfaces';

const useUpdateIO = () => {
	const [
		updateIONumberForNormalServices,
		{
			isLoading: isIOUpdating,
			isSuccess: ioUpdateSucess,
			isError: isIoUpdateError,
			error: ioError,
		},
	] = useUpdateIONumberMutation();

	const [
		updateIONumberAzureForAzureServices,
		{
			isLoading: isAzureIOUpdating,
			isSuccess: azureIoUpdateSuccess,
			isError: isAzureIoUpdateError,
			error: azureIOError,
		},
	] = useUpdateIONumberAzureMutation();
	const dispatch = useDispatch();
	const updateIONumber = useCallback(
		async ({ cc_Id, serviceList, ioNumber }: IIONumberUpdateRequest) => {
			let _selectedServices = [];

			if (!Array.isArray(serviceList)) {
				if ('clusterInfo' in serviceList) {
					if (serviceList.clusterInfo !== undefined) {
						for (let _clusterInfo of serviceList.clusterInfo) {
							_selectedServices.push({
								...serviceList,
								additionalInfo: _clusterInfo,
								ioNumber: ioNumber || serviceList.ioNumber,
							});
						}
					}
				} else {
					_selectedServices.push({
						...serviceList,
						ioNumber: ioNumber || serviceList.ioNumber,
					});
				}
			} else {
				for (let service of serviceList) {
					if ('clusterInfo' in service) {
						if (service.clusterInfo !== undefined) {
							for (let _clusterInfo of service.clusterInfo) {
								_selectedServices.push({
									...service,
									additionalInfo: _clusterInfo,
									ioNumber,
								});
							}
						}
					} else {
						_selectedServices.push({ ...service, ioNumber });
					}
				}
			}

			// let normalServices = _selectedServices.filter(
			// 	(service) =>
			// 		!service.serviceName.toLocaleLowerCase().includes('gb4') &&
			// 		service.serviceId !== '20023510' &&
			// 		Object.keys(service).indexOf('additionalInfo') !== -1
			// );

			// let azureServices = _selectedServices.filter(
			// 	(service) =>
			// 		service.serviceName.toLocaleLowerCase().includes('gb4') &&
			// 		service.serviceId === '20023510' &&
			// 		Object.keys(service).indexOf('additionalInfo') === -1
			// );

			// if (normalServices.length > 0) {
				await updateIONumberForNormalServices({
					cc_Id,
					serviceList: _selectedServices,
					ioNumber,
				})
					.unwrap()
					.then((payload: any) => {
						toast.success('IO Number Updated Successfully');
						dispatch(setRefetchServices());
					})
					.catch((e: any) =>
						toast.error('Error Updating IO Number', {
							position: 'bottom-right',
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
						})
					);
			// }

			// if (azureServices.length > 0) {
			// 	await updateIONumberAzureForAzureServices({
			// 		cc_Id,
			// 		serviceList: azureServices,
			// 		ioNumber,
			// 	})
			// 		.unwrap()
			// 		.then((payload: any) => {
			// 			toast.success('IO Number Updated Successfully', {
			// 				position: 'bottom-right',
			// 				autoClose: 5000,
			// 				hideProgressBar: false,
			// 				closeOnClick: true,
			// 				pauseOnHover: true,
			// 				draggable: true,
			// 				progress: undefined,
			// 			});
			// 			dispatch(setRefetchServices());
			// 		})
			// 		.catch((e: any) =>
			// 			toast.error('Error Updating IO Number', {
			// 				position: 'bottom-right',
			// 				autoClose: 5000,
			// 				hideProgressBar: false,
			// 				closeOnClick: true,
			// 				pauseOnHover: true,
			// 				draggable: true,
			// 				progress: undefined,
			// 			})
			// 		);
			// }
		},
		[]
	);

	return {
		updateIONumber,
		isLoading: isIOUpdating || isAzureIOUpdating,
		isSucess: ioUpdateSucess || azureIoUpdateSuccess,
		isError: isIoUpdateError || isAzureIoUpdateError,
		error: ioError || azureIOError,
	};
};

export default useUpdateIO;
