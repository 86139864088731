import { useMemo } from 'react';
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';
import { setSettingsModal, settingsModalState } from '../../store/commonSlice';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
	useGetAllActiveCostCentersQuery,
	useGetAllCostCentersQuery,
} from '../../store/apiSlice';

const NewUsersList = () => {
	const {
		data: allCostCenters = [],
		isLoading,
		isSuccess,
		isError,
		error,
	} = useGetAllCostCentersQuery();

	const { data: activeCostCenters = [] } = useGetAllActiveCostCentersQuery();
	const dispatch = useDispatch();
	const openModal = useSelector(settingsModalState);

	const newCostCenterUsers = useMemo(() => {
		return allCostCenters.filter(
			(cc: any) => cc.isvisible && cc.isnewresponsible
		);
	}, [allCostCenters]);

	const rowClass = (data: any) => {
		return {
			'highlight-row':
				activeCostCenters.findIndex(
					(_active: any) => _active.costCenter === data.target_costcenter
				) !== 1
					? true
					: false,
		};
	};
	return (
		<Dialog
			header="New Users List"
			visible={openModal.newCostCenterUsers}
			style={{ width: '50vw' }}
			onHide={() => {
				dispatch(setSettingsModal({ newCostCenterUsers: false }));
			}}
		>
			<DataTable
				value={newCostCenterUsers}
				responsiveLayout="scroll"
				rowClassName={rowClass}
			>
				<Column
					field="target_costcenter"
					header="Cost Center"
					sortable
				></Column>
				<Column field="name" header="Name" sortable></Column>
				<Column
					field="target_responsible"
					header="Cost Center User"
					sortable
				></Column>
				<Column
					field="user_responsible"
					header="Cost Center User ID"
					sortable
				></Column>
			</DataTable>
		</Dialog>
	);
};

export default NewUsersList;
