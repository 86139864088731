enum PAGES {
	'ACTUALS' = 'ACTUALS',
	'ITSERVICES' = 'ITSERVICES',
	'PICAS' = 'PICAS',
	'SERVICES' = 'SERVICES',
	'CHARGING_REPORT' = 'CHARGING_REPORT',
	'WILDCARD_MAPPING' = 'WILDCARD_MAPPING',
	'NEW_SERVICES' = 'NEW_SERVICES',
	'CHARGING_REPORT_MBAG' = 'CHARGING_REPORT_MBAG',
	'WILDCARD_MAPPING_MBAG' = 'WILDCARD_MAPPING_MBAG',
	'NEW_SERVICES_MBAG' = 'NEW_SERVICES_MBAG',
	'PICAS_LIST' = 'PICAS_LIST',
	'ASSET_SUMMARY' = 'ASSET_SUMMARY',
	'ARCHIVE' = 'ARCHIVE',
	'ARCHIVE_SERVICES' = 'ARCHIVE_SERVICES',
	'UNDEFINED' = 'UNDEFINED',
	'WORKPLACE_LIST' = 'WORKPLACE_LIST',
	'INVALID' = 'INVALID',
}

export default PAGES;
