import MenuItem from './MenuItem';
import menuItems from '../../../utils/json/navigation.json';

import { useSelector } from 'react-redux';
import { selectSidebar } from '../../../store/commonSlice';

import { useGetICAVersionQuery } from '../../../store/apiSlice';

import './styles.scss';
import '../style.scss';
import { useEffect, useMemo, useState } from 'react';

import { IMenuItem } from '../menu/MenuItem';

const Menu = () => {
	const {
		data = [],
		isLoading,
		isSuccess,
		isError,
		error,
	} = useGetICAVersionQuery();
	const sidebar = useSelector(selectSidebar);
	const [printMenu, setPrintMenu] = useState<IMenuItem[]>();

	const archiveOptions = useMemo(() => {
		const archiveSubMenu: IMenuItem[] = data!.map((item) => ({
			name: item.name,
			icon: 'circle',
			access: 'all',
			route: item.id.toString(),
			subItems: [],
		}));
		const newItem = menuItems?.map((item) => {
			if (item.name === 'archive')
				return {
					...item,
					subItems: archiveSubMenu,
				};
			else return item;
		});
		return newItem;
	}, [data]);

	return (
		<ul className={`menu ${sidebar ? '' : 'align-items-center'}`}>
			{archiveOptions &&
				archiveOptions.map((menuItem) => (
					<MenuItem key={menuItem.name} data={menuItem as IMenuItem} />
				))}
		</ul>
	);
};

export default Menu;
