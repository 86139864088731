import './style.scss';

import { Icon } from '@iconify/react';
import { useLocation, useNavigate } from 'react-router-dom';
import DisplayIcon from '../icon';
import { useSelector, useDispatch } from 'react-redux';
import {
	getSearchTerm,
	setCostcenterId,
	setServiceOptions,
	getServiceOptions,
	getCostcenterId,
	getIsAdmin,
	getIsCostController,
} from '../../store/commonSlice';
import { useEffect, useState } from 'react';
import { dualSearch, searchHighlighter } from '../../utils/utility-function';
import ReactTooltip from 'react-tooltip';
import { useIsUserDeputyQuery } from '../../store/UserApi';
import PAGES from '../../utils/pages.enum';
import useFindCurrentLocation from '../../hooks/useFindCurrentLocation';
import PicasDialog from '../../views/service/picas-dialog';
import { useGetCurrentUserQuery } from '../../store/UserApi';

export interface IService {
	id_pk: number;
	serviceId: string;
	serviceName: string;
	said: string;
	additionalInfo: string;
	plannedAmount: number;
	bookedAmount: number;
	isComment: boolean;
	ioNumber: string;
	isHistory: boolean;
	isILVComment: boolean;
	month: number;
	yearlyTotal: number
	clusterInfo?: string[];
}

export interface IProp {
	costCenterId: number;
	name: string;
	costCenter: string;
	targetResponsible: string;
	userResponsible: string;
	deputy: string;
	amount: number;
	plannedAmount: number;
	bookedAmount: number;
	picasAmount: number;
	newPicasAmount: number;
	services: IService[];
	ccMaster: string;
}

const CostCenterCard = ({
	data,
	isArchive,
	showPicas,
	ownData,
	showService,
}: {
	data: IProp;
	isArchive?: boolean;
	showPicas: boolean;
	ownData?: IProp[];
	showService?: boolean;
}) => {
	const searchTerm = useSelector(getSearchTerm);
	const pageName = useFindCurrentLocation();
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const { data: deputyList = [] } = useIsUserDeputyQuery();
	const costCenterId = useSelector(getCostcenterId);

	const allModals = useSelector(getServiceOptions);
	// const [primeServices, setPrimeServices] = useState<IProp>();
	const isAdmin = useSelector(getIsAdmin);
	const isCostController = useSelector(getIsCostController);
	const { data: currentUser, isLoading: isLoadingUser } =
		useGetCurrentUserQuery(0);
	const hasAccess = isAdmin || isCostController || data?.userResponsible === currentUser?.ntid || currentUser?.ntid && data?.deputy && data?.deputy.split(',').includes(currentUser?.ntid) || deputyList.map((item) => item.ids).includes(data.costCenterId)

	const ownCostCenter = ownData?.some(e => e.costCenterId === data.costCenterId)

	const filterSearchTerm = (items: any) =>
		Object.values(items).findIndex((chunk: any) =>
			chunk.toString().toLowerCase().includes(searchTerm.toLowerCase())
		) !== -1 ||
		dualSearch(
			Object.values(items).join('').toLowerCase(),
			searchTerm.toLowerCase().split('+')
		);
	return (
		<div
			className="col-12 md:col-6 lg:col-6 xl:col-4"
			onClick={() => {


				if (pageName === PAGES.PICAS) {
					if (hasAccess || ownCostCenter) {
						dispatch(setServiceOptions({ picasDetails: true }));
						dispatch(setCostcenterId(`${data.costCenterId}`));
					}
				} else {
					if (
						window.getSelection() &&
						window.getSelection()!.toString().length > 0
					)
						return true;
					navigate(`${data.costCenterId}`, {
						state: { prevPath: location.pathname },
					});
				}
			}}
		>
			<div
				className="card transition-all transition-linear transition-duration-300"
				onClick={() => { }}
			>
				<div className="flex align-items-start justify-content-between">
					<div className="flex flex-column  mb-4 pb-2">
						<h4
							className=""
							dangerouslySetInnerHTML={searchHighlighter(data.name, searchTerm)}
						></h4>
						<h6>{data.costCenter}</h6>
					</div>
					{isArchive && (
						<div data-tip="Archived Information">
							<DisplayIcon
								icon="colorLock"
								className="mr-3 color-icon__medium"
							/>
						</div>
					)}
					{deputyList.map((item) => item.ids).includes(data.costCenterId) && (
						<div data-tip="User is deputy">
							<DisplayIcon
								icon="colorDeputy"
								className="mr-3 color-icon__medium"
							/>
						</div>
					)}
				</div>
				<div className="flex justify-content-between">
					{pageName === PAGES.ITSERVICES && <>
						<div className="flex align-items-center">
							<DisplayIcon
								icon="booked"
								className="card-icon fix-size green-text"
							/>
							<div className="flex flex-column">
								<span className="green-text-muted">Booked </span>
								<h3 className="green-text">
									{data.services.filter(filterSearchTerm).length === 0
										? data.bookedAmount.toLocaleString('de-DE')
										: data.services
											.filter(filterSearchTerm)
											.filter(_service => _service.serviceName !== "Picas Report")
											.reduce((acc, item) => (acc += item.bookedAmount), 0)
											.toLocaleString('de-DE')}{' '}
									€
								</h3>{' '}
							</div>
						</div>
						<div className="flex align-items-center">
							<DisplayIcon icon="planned" className="card-icon" />

							<div className="flex flex-column">
								<span className="planned">Planned</span>
								<h3 className="planned">
									{data.services.filter(filterSearchTerm).length === 0
										? data.plannedAmount.toLocaleString('de-DE')
										: data.services
											.filter(filterSearchTerm)
											.reduce((acc, item) => (acc += item.plannedAmount), 0)
											.toLocaleString('de-DE')}{' '}
									€
								</h3>
							</div>
						</div>
					</>}
					{(showPicas === true && data.picasAmount || data.newPicasAmount) >= 0 && (hasAccess || ownCostCenter || deputyList.map((item) => item.ids).includes(data.costCenterId)) && (
						<>
						{/* <div className="flex align-items-center mr-auto">
							<DisplayIcon icon="picas" className="card-icon" />
							<div className="flex flex-column">
								<span className="picas">Picas</span>
								<h3 className="picas">
									{data.picasAmount.toLocaleString('de-DE')} €
								</h3>
							</div>
						</div> */}
							<div className="flex align-items-center ml-auto">
								<DisplayIcon icon="picas" className="card-icon" />
								<div className="flex flex-column">
									<span className="picas">Workplace</span>
									<h3 className="picas">
										{data.newPicasAmount.toLocaleString('de-DE')} €
									</h3>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
			{allModals.picasDetails === true && Number(costCenterId) === data.costCenterId && <PicasDialog />}
		</div>
	);
};

export default CostCenterCard;
