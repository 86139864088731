import { Skeleton } from 'primereact/skeleton';
import { useDispatch } from 'react-redux';
import { useGetAllCostCentersQuery } from '../../store/apiSlice';
import { setCostCenterFilter } from '../../store/commonSlice';
import CustomAutoComplete from '../autocomplete/autocomplete';

const AutoCompleteCostCenterId = ({
	defaultValue,
	callback,
	onBlur,
	className,
	classNameForLabel,
	field,
	label,
}: {
	field?: any;
	onBlur?: any;
	defaultValue?: any;
	callback?: (param?: any) => any;
	className?: string;
	classNameForLabel?: string;
	label?: string;
}) => {
	const dispatch = useDispatch();

	const {
		data: allList = [],
		isLoading: isListLoading,
		isSuccess: isListSuccess,
		isError: isListError,
		error: listError,
	} = useGetAllCostCentersQuery();

	const itemTemplate = (item: any) => {
		return (
			<div className="country-item">
				<div>
					{item.target_costcenter} | {item.name}
				</div>
			</div>
		);
	};

	return isListLoading ? (
		<Skeleton height="2rem" className="mb-2"></Skeleton>
	) : (
		<CustomAutoComplete
			onBlur={onBlur}
			className={className}
			classNameForLabel={classNameForLabel}
			target="target_costcenter"
			customId={`target_costcenter`}
			label={label ? label : 'Cost Center'}
			itemTemplate={itemTemplate}
			inputList={allList
				.filter((item) => item.isvisible == true)
				.map((item) => ({
					id: item.id,
					target_costcenter: item.target_costcenter,
					name: item.name,
					target_responsible: item.target_responsible,
					user_responsible: item.user_responsible,
				}))}
			field={field}
			callback={callback}
			defaultValue={
				defaultValue
					? {
							target_costcenter: allList.find(
								(item) => item.id === defaultValue.id
							)?.target_costcenter,
							user_responsible: allList.find(
								(item) => item.id === defaultValue.id
							)?.user_responsible,
							id: defaultValue.id,
							name: allList.find((item) => item.id === defaultValue.id)?.name,
							target_responsible: allList.find(
								(item) => item.id === defaultValue.id
							)?.target_responsible,
					  }
					: null
			}
		/>
	);
};

export default AutoCompleteCostCenterId;
